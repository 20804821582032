import {
  Component,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {filter, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {toObservable} from '@angular/core/rxjs-interop';
import {environment} from '../environments/environment';

import {
  AuthenticationService,
  BillingService,
  MobileService,
  NotificationService,
} from './core/services';
import {
  BillingPlan,
  SideNavToggle,
  User,
  Response,
  CustomError,
} from './shared/models';
import {BillingPlanStore} from './shared/state';
import {OrganizationService} from './core/services';
import {getPlainUrl} from './shared/helpers';
import {AuthStore, Session} from './core/store/auth/auth.store';
import {NgIf} from '@angular/common';
import {SidenavComponent} from './shared/components';
import {BodyComponent} from './shared/components';

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [NgIf, SidenavComponent, BodyComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  // Host Listeners
  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    const windowWidth: number = (event.target as Window).innerWidth;
    const windowHeight: number = (event.target as Window).innerHeight;
    this.mobileService.setIsMobile =
      this.mobileService.checkIfMobile(windowWidth);
    this.mobileService.setMobileWindowSize =
      this.mobileService.checkMobileWindowSize(windowWidth);
    this.mobileService.setBreakpoint(windowWidth);
    this.mobileService.setWindowHeigh(windowHeight);
  }

  // State Variables
  title = 'AdClicks-UI';
  hideSidenav = false;
  billingInfoLoaded: boolean;
  billingInfo: BillingPlan | undefined;
  isSideNavCollapsed = false;
  isShareReport = false;
  hideMobileSidenav = false;

  // Properties
  screenWidth = 0;
  organization = localStorage.getItem('organization');
  user: User;

  sharingRoutes: string[] = ['/view-reports', '/not-found'];

  noPrint: string[] = [
    '/print',
    '/pagePrint',
    '/widgetPrint',
    '/thumbnailPrint',
    ...this.sharingRoutes,
  ];

  noNavbarRoutes: string[] = [
    '/reset',
    ...this.noPrint,
    '/expired',
    '/canceled',
    '/widget',
    ...this.sharingRoutes,
  ];

  // Subscription
  subs: Subscription = new Subscription();

  // Store
  public readonly billinPlanStoreSignal = inject(BillingPlanStore);
  public readonly authStore = inject(AuthStore);

  constructor(
    private router: Router,
    private billingService: BillingService,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private mobileService: MobileService,
    private translate: TranslateService
  ) {
    const language = localStorage.getItem('language') as string;
    if (language) {
      translate.use(language);
    } else {
      localStorage.setItem('language', 'en');
    }
    this.authenticationService
      .getCurrentUserState()
      .subscribe((el: User): void => {
        this.user = el;
        this.setWindowUserLayer();
      });

    const watchBillinPlan = toObservable(this.billinPlanStoreSignal.getPlan);
    this.subs.add(
      watchBillinPlan.subscribe((plan: BillingPlan | null): void => {
        if (plan) {
          this.billingInfo = plan;
          Boolean(this.billingInfo) && this.setWindowBillingLayer();
        }
      })
    );
  }

  ngOnInit(): void {
    this.mobileService.setIsMobile = this.mobileService.checkIfMobile();
    this.mobileService.setMobileWindowSize =
      this.mobileService.checkMobileWindowSize();
    this.mobileService.setBreakpoint();
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        ),
        distinctUntilChanged(
          (previous: NavigationEnd, current: NavigationEnd) => {
            /* Avoid to call billing twice with params and fragments (except 'reports') */
            const urlsOmit = ['reports'];
            const prevUrl = getPlainUrl(previous.url, urlsOmit);
            const currentUrl = getPlainUrl(current.url, urlsOmit);
            const prevRedirectsUrl = getPlainUrl(
              previous.urlAfterRedirects,
              urlsOmit
            );
            const currentRedirectsUrl = getPlainUrl(
              current.urlAfterRedirects,
              urlsOmit
            );

            return (
              prevUrl === currentUrl && prevRedirectsUrl === currentRedirectsUrl
            );
          }
        )
      )
      .subscribe((): void => {
        this.isShareReport = this.sharingRoutes.some((r: string) =>
          this.router.url?.includes(r)
        );
        const session = this.router.parseUrl(this.router.url).queryParams
          .session;
        const loginAs = this.router.parseUrl(this.router.url).queryParams
          .loginAs;
        const loginFromDomain = this.router.parseUrl(this.router.url)
          .queryParams.loginFromDomain;
        const billingInfo = this.router.parseUrl(this.router.url).queryParams
          .billingInfo;
        let currentUrlSession = null;
        if (localStorage.getItem('currentUrlSession') !== null) {
          currentUrlSession = this.router.parseUrl(
            localStorage.getItem('currentUrlSession') as string
          ).queryParams.session;
        }
        this.hideSidenav = this.noNavbarRoutes.some((route) =>
          this.router.url.includes(route)
        );

        this.hideMobileSidenav = this.noPrint.some((route) =>
          this.router.url.includes(route)
        );

        if (!this.authStore.getSession()) {
          this.setSessionToStore(session, currentUrlSession);
        }
        if (this.authStore.getSession()) {
          if (loginAs) {
            localStorage.setItem('loginAs', loginAs);
            localStorage.setItem('loginFromDomain', loginFromDomain);
            const plan: BillingPlan = billingInfo?.data?.plan || {};
            this.billinPlanStoreSignal.updateBillingPlanMethod(plan);
          }

          if (Object.keys(this.user).length === 0) {
            this.loadUserData();
          } else if (!this.user.roles?.includes('ROLE_EXTERNAL')) {
            this.getBillingInformation();
          } else {
            this.billingInfoLoaded = true;
          }
        } else if (
          !this.authenticationService.isLoggedIn() ||
          !this.authenticationService.isTokenValid()
        ) {
          this.authenticationService.clearSession();
          if (!this.isShareReport) {
            if (!this.organization) {
              this.organizationService.getOrg();
            } else {
              window.location.href =
                `${environment.coreUrl}/login?orgId=` +
                JSON.parse(this.organization).id;
            }
          }
        }
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((evt) => {
        if ((evt as NavigationStart).url.includes('createdOrg')) {
          localStorage.setItem('createdOrg', 'true');
        }

        if (!(evt as NavigationStart).url.includes('session')) {
          if (!(evt as NavigationStart).url.includes('login')) {
            localStorage.setItem(
              'currentUrl',
              decodeURIComponent((evt as NavigationStart).url)
            );
          }
        }

        if ((evt as NavigationStart).url.includes('session')) {
          localStorage.setItem(
            'currentUrlSession',
            decodeURIComponent((evt as NavigationStart).url)
          );
        }

        if (!this.user && localStorage.getItem('loginAs')) {
          this.loadUserData();
        }
      });
  }

  /** Send some information to Google Tag Manager **/
  setWindowUserLayer(): void {
    if (Object.keys(this.user).length > 0 && window.dataLayer) {
      window.dataLayer.push({
        'uid': this.user.id || this.user._id,
        'uemail': this.user.email,
        'ucreated': this.user.creationDate,
      });
    }
  }

  setWindowBillingLayer(): void {
    if (
      this.billingInfo &&
      Object.keys(this.billingInfo).length > 0 &&
      window.dataLayer
    ) {
      window.dataLayer.push({
        'billingPlan': this.billingInfo.type,
      });
    }
  }

  loadUserData(): void {
    this.authenticationService.getUserData().subscribe({
      next: (res) => {
        this.user = res;
        this.setWindowUserLayer();
        localStorage.setItem('currentUser', JSON.stringify(this.user));
        this.authenticationService.setCurrentUserState(this.user);
        if (!this.user.roles?.includes('ROLE_EXTERNAL')) {
          this.getBillingInformation();
        } else {
          this.billingInfoLoaded = true;
        }
      },
      error: (): void => {},
      complete: () => {
        const urlArray = localStorage.getItem('currentUrl')?.split('#');
        const currentUrl = urlArray ? urlArray[0] : null;
        const createdOrg = !!localStorage.getItem('createdOrg');
        const fragment = urlArray ? urlArray[1] : null;

        if (createdOrg) {
          currentUrl && localStorage.removeItem('currentUrl');
          localStorage.removeItem('createdOrg');
          this.router.navigate(['/get-started']).then();
        } else {
          if (currentUrl) {
            localStorage.removeItem('currentUrl');
            if (fragment) {
              this.router.navigate([currentUrl], {fragment}).then();
            } else {
              this.router.navigate([currentUrl]).then();
            }
            return;
          } else {
            this.router.navigate(['/home']).then();
          }
        }
      },
    });
  }

  getBillingInformation(): void {
    this.billingService.getPaymentDetails().subscribe({
      next: (res: Response): void => {
        const plan: BillingPlan = res?.data?.plan || {};
        this.billinPlanStoreSignal.updateBillingPlanMethod(plan);
      },
      error: (err: CustomError): void => {
        this.notificationService.error(
          'Error loading billing information.',
          5000
        );
      },
    });
  }

  onToggleSideNav(data: SideNavToggle): void {
    this.screenWidth = data.screenWidth;
    this.isSideNavCollapsed = data.collapsed;
  }

  setSessionToStore(session, currentUrlSession): void {
    if (session) {
      this.authStore.setSessionMethod(JSON.parse(session), this.router);
    }

    if (!session) {
      this.authStore.setSessionMethod(
        this.authenticationService.getSession() as Session
      );
    }

    if (!session && currentUrlSession) {
      const currentUrlSessionStorage =
        this.authenticationService.getCurrenUrlSession();
      const sessionStorage = this.authStore.getSession();

      if (sessionStorage) {
        this.authStore.setSessionMethod(sessionStorage as Session, this.router);
      }

      if (!sessionStorage && currentUrlSessionStorage) {
        const toStore = {...currentUrlSessionStorage} as Session;
        this.authStore.setSessionMethod(toStore, this.router);
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
