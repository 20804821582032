import {
  signalStore,
  withComputed,
  withMethods,
  withState,
  WritableStateSource,
} from '@ngrx/signals';
import {updateState, withDevtools} from '@angular-architects/ngrx-toolkit';
import {Router} from '@angular/router';
import {computed} from '@angular/core';

export interface Session {
  acces_token: string;
  expires_at: number;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: 'bearer';
  twoFactorEnabled: {
    enabled: boolean;
  };
}

type AuthStateSignal = {
  session: Session | null;
};

const authStoreInitialState: AuthStateSignal = {session: null};

export const AuthStore = signalStore(
  {providedIn: 'root'},
  withDevtools('AuthStore'),
  // auth state
  withState(authStoreInitialState),

  // auth store methods/computed/hooks
  withComputed(({session}) => ({
    getSession: computed(() => {
      return session();
    }),
  })),
  withMethods((store) => ({
    setSessionMethod: (session: Session, router?: Router): void =>
      _setSession(store, session, router),
  }))
);

function _setSession(
  store: WritableStateSource<AuthStateSignal>,
  session: Session,
  router?: Router
): void {
  session = {
    ...session,
    expires_at: new Date().getTime() + session?.expires_in * 1000,
  };
  if (router) {
    _updateCurrentUrlSession(router);
  }
  updateState(store, '[Auth] Update Session', (state) => ({
    session: {...state.session, ...session},
  }));
  localStorage.setItem('session', JSON.stringify(session));
}

function _updateCurrentUrlSession(router: Router): void {
  const urlSession = router.parseUrl(
    localStorage.getItem('currentUrlSession') as string
  ).queryParams.session;
  if (urlSession) {
    let urlSessionObject = JSON.parse(urlSession);
    if (!urlSessionObject.expires_at) {
      const expires_at =
        new Date().getTime() + urlSessionObject.expires_in * 1000;
      urlSessionObject = {...urlSessionObject, expires_at};
    }

    const sessionString = JSON.stringify(urlSessionObject);
    const newCurrentUrlSession = `/?session=${sessionString}`;
    localStorage.setItem('currentUrlSession', newCurrentUrlSession);
  }
}
