import {Routes} from '@angular/router';
import {
  LoginGuard,
  FreeTrialGuard,
  RoleGuard,
  ShareReportGuard,
  AuthenticationGuard,
  CanceledPlanGuard,
} from './core/guards';

export const APP_ROUTES: Routes = [
  // Public Paths
  {
    path: 'view-reports',
    canLoad: [ShareReportGuard],
    loadChildren: () =>
      import('./modules/share-report/share-report.routes').then(
        (m) => m.SHARE_REPORT_ROUTES
      ),
  },
  {
    path: 'share-reports',
    redirectTo: 'view-reports',
  },
  {
    path: 'not-found',
    loadComponent: () =>
      import('./shared/components').then((c) => c.SharedErrorComponent),
    canActivate: [ShareReportGuard],
  },
  // Private Paths
  {
    path: '',
    loadChildren: () =>
      import('./modules/reporting/reporting.routes').then(
        (m) => m.REPORTING_ROUTES
      ),
    canLoad: [LoginGuard],
    canActivate: [FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./modules/companies/companies.routes').then(
        (m) => m.COMPANIES_ROUTES
      ),
    canLoad: [LoginGuard],
    canActivate: [RoleGuard, FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'connections',
    loadChildren: () =>
      import('./modules/connections/connections.routes').then(
        (m) => m.CONNECTIONS_ROUTES
      ),
    canLoad: [LoginGuard],
    canActivate: [RoleGuard, FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./modules/templates/templates.routes').then(
        (m) => m.TEMPLATES_ROUTES
      ),
    canLoad: [LoginGuard],
    canActivate: [RoleGuard, FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'themes',
    loadChildren: () =>
      import('./modules/theme/theme.routes').then((m) => m.THEME_ROUTES),
    canLoad: [LoginGuard],
    canActivate: [RoleGuard, FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.routes').then(
        (m) => m.SETTINGS_ROUTES
      ),
    canLoad: [LoginGuard],
    canActivate: [RoleGuard, FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'monitoring',
    loadChildren: () =>
      import('./modules/monitoring/performance.routes').then(
        (m) => m.PERFORMANCE_ROUTES
      ),
    canLoad: [LoginGuard],
    canActivate: [RoleGuard, FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'get-started',
    loadChildren: () =>
      import('./modules/get-started/get-started.routes').then(
        (m) => m.GET_STARTED_ROUTES
      ),
    canLoad: [LoginGuard],
    canActivate: [RoleGuard, FreeTrialGuard, CanceledPlanGuard],
  },
  {
    path: 'expired',
    loadComponent: () =>
      import('./shared/components').then((c) => c.TrialExpiredComponent),
    canActivate: [FreeTrialGuard],
  },
  {
    path: 'canceled',
    loadComponent: () =>
      import('./shared/components').then((c) => c.TrialExpiredComponent),
    canActivate: [CanceledPlanGuard],
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'companies',
    redirectTo: 'clients',
  },
  {
    path: '**',
    loadComponent: () =>
      import('./shared/components').then((c) => c.ErrorPageComponent),
    canActivate: [AuthenticationGuard, FreeTrialGuard, CanceledPlanGuard],
  },
];
