import {Injectable, inject} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {Observable, Subject, throwError, of} from 'rxjs';
import {catchError, first, switchMap, tap} from 'rxjs/operators';
import {ObservableInput} from 'rxjs/internal/types';
import {AuthenticationService} from '../services';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {AuthStore, Session} from '../store/auth/auth.store';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private isRefreshingToken = false;
  private refreshTokenSubject = new Subject();
  private router = inject(Router);
  public readonly authStore = inject(AuthStore);

  constructor(
    private authService: AuthenticationService,
    private translate: TranslateService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const skipIntercept = req.headers.get('skip') === 'true';

    if (skipIntercept) {
      req = req.clone({
        headers: req.headers.delete('skip'),
      });
      return next.handle(req);
    }

    if (this.authService.isLoggedIn()) {
      // Clone the request to add the new header.
      return this.addToken(req).pipe(
        switchMap((authReq) =>
          next.handle(authReq).pipe(catchError(this.handleError(authReq, next)))
        )
      );
    } else {
      return next.handle(
        req.clone({
          url: this.getUrl(req),
          setHeaders: {language: this.translate.currentLang ?? 'en'},
        })
      );
    }
  }

  getUrl(request: HttpRequest<unknown>): string {
    const fullUrl = request.url.replace(environment.fullApiUrl, '');
    const url = fullUrl.replace(/^\//, '');
    return environment.fullApiUrl + `/${url}`;
  }

  addToken(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    const authToken = this.authService.getToken();
    if (!authToken) {
      return this.createUnauthorizedError();
    }
    const loginAs = localStorage.getItem('loginAs');
    let language = this.translate.currentLang;
    if (language === undefined || language === null) {
      language = 'en';
    }

    if (loginAs) {
      return of(
        request.clone({
          headers: request.headers
            .set('Authorization', `Bearer ${authToken}`)
            .set('x-switch-user', loginAs)
            .set('language', language),
          url: this.getUrl(request),
        })
      );
    } else {
      return of(
        request.clone({
          headers: request.headers
            .set('Authorization', `Bearer ${authToken}`)
            .set('language', language),
          url: this.getUrl(request),
        })
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): (err: any) => ObservableInput<any> {
    return (err) => {
      if (
        err instanceof HttpErrorResponse &&
        err.error instanceof Blob &&
        err.error.type === 'application/json'
      ) {
        return new Promise<unknown>((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.onload = (event): void => {
            try {
              const result = event.target?.result as string;
              const error = event && JSON.parse(result);
              reject(
                new HttpErrorResponse({
                  error,
                  headers: err.headers,
                  status: err.status,
                  statusText: err.statusText,
                  url: err.url ?? undefined,
                })
              );
            } catch (e) {
              return reject(err);
            }
          };
          fileReader.onerror = (): void => reject(err);
          fileReader.readAsText(err.error);
        });
      } else if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          if (this.isRefreshingToken) {
            return this.refreshTokenSubject.pipe(
              first(),
              switchMap(() => this.retryRequest(request, next))
            );
          } else {
            this.isRefreshingToken = true;
            const session = this.authStore.getSession();
            const payload = {
              username: this.authService.getCurrentUser()?.email || '',
              refresh_token: session?.refresh_token,
            };

            return this.authService.refreshToken(payload).pipe(
              tap((newSession) =>
                this.authStore.setSessionMethod(
                  newSession as Session,
                  this.router
                )
              ),
              tap(() => this.refreshTokenSubject.next(true)),
              tap(() => (this.isRefreshingToken = false)),
              catchError(() => this.createUnauthorizedError()),
              switchMap(() => this.retryRequest(request, next))
            );
          }
        }
      }
      if (err) {
        err.message = navigator.onLine
          ? err?.error?.error?.message ||
            err?.error?.message ||
            err?.error?.detail ||
            err?.message ||
            'Something went wrong!'
          : 'No Internet Connection';
      }
      return throwError(err);
    };
  }

  retryRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.addToken(request).pipe(
      switchMap((authReq) =>
        next.handle(authReq).pipe(
          catchError((err) => {
            if (err.status === 401) {
              return this.createUnauthorizedError(err.message);
            }
            return throwError(err);
          })
        )
      )
    );
  }

  createUnauthorizedError(errMessage?: string): Observable<never> {
    const organization = localStorage.getItem('organization');
    this.isRefreshingToken = false;
    this.authService.clearSession();

    if (organization) {
      window.location.href =
        `${environment.coreUrl}/login?orgId=` + JSON.parse(organization).id;
    }

    const err = new Error(errMessage || 'Unauthorized access');
    return throwError(() => err);
  }
}
