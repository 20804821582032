<ng-container
  *ngIf="!hideSidenav && user && (billingInfo || billingInfoLoaded)"
>
  <app-sidenav (toggleSideNav)="onToggleSideNav($event)"></app-sidenav>
</ng-container>

<app-body
  *ngIf="(user && (billingInfo || billingInfoLoaded)) || isShareReport"
  [collapsed]="isSideNavCollapsed"
  [screenWidth]="screenWidth"
  [hideSidenav]="hideSidenav"
  [isShareReport]="isShareReport"
  [hideMobileSidenav]="hideMobileSidenav"
/>
